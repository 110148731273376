<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :facilityStatusTable.sync="facilityStatusTable"
          :hcfFacilityTypeItems.sync="hcfFacilityTypeItems"
          :contentHeight="contentHeight"
          @getDetail="getDetail"
          @plantChange="plantChange"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'facility-status-table-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'base',
      facilityStatusTable: {
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        year: '',
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      },
      hcfFacilityTypeItems: [],
      editable: true,
      detailUrl: '',
      baseUrl: '',
      managerListUrl: '',
      facilityListUrl: '',
      powerMachineListUrl: '',
      deviceListUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 50);
    },
    disabled() {
      return false;
    },
    tabItems() {
      let _items = [
        { name: 'base', icon: 'info', label: 'LBLBASEINFO', component: () => import(`${'./facilityStatusTableBaseInfo.vue'}`) },
      ];
      if (this.popupParam.chmFacilityStatusTableId) {
        _items = this.$_.concat(_items, [
          { name: 'hazardChem', icon: 'science', label: '유해화학물질', component: () => import(`${'./facilityStatusTableHazardChem.vue'}`) },
          { name: 'facility', icon: 'home_filled', label: '시설', component: () => import(`${'./facilityStatusTableFacility.vue'}`) },
          { name: 'equip', icon: 'precision_manufacturing', label: 'LBLEQUIP', component: () => import(`${'./facilityStatusTableEquip.vue'}`) },
        ])
      }
      return _items;
    } 
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.facilityStatusTable.get.url;
      this.baseUrl = selectConfig.chm.facilityStatusTable.base.url;
      this.managerListUrl = selectConfig.mdm.senior.status.list.url
      this.facilityListUrl = selectConfig.env.facility.list.url
      this.powerMachineListUrl = selectConfig.psi.pfi.power.list.url;
      this.deviceListUrl = selectConfig.psi.pfi.device.list.url;
      // code setting
      this.$comm.getComboItems('HCF_FACILITY_TYPE_CD').then(_result => {
        this.hcfFacilityTypeItems = _result
      });
      // list setting
      this.getDetail();
    },
    plantChange() {
      this.getBase(false);
      this.getSubInfo();
    },
    getDetail() {
      if (this.popupParam.chmFacilityStatusTableId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmFacilityStatusTableId)
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.facilityStatusTable, _result.data)
          this.getSubInfo();
        },);
      } else {
        this.getBase(true);
      }
    },
    getBase(flag) {
      let _plantCd = flag ? this.$store.getters.user.plantCd : this.facilityStatusTable.plantCd
      if (_plantCd) {
        this.$http.url = this.baseUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: _plantCd
        };
        this.$http.request((_result) => {
          this.$_.extend(this.facilityStatusTable, _result.data)
          // this.$set(this.facilityStatusTable, 'permitDate', this.$comm.getToday());
        },);
      }
    },
    getSubInfo() {
      if (this.facilityStatusTable.plantCd && this.popupParam.chmFacilityStatusTableId) {
        // 유해화학물질 관리자
        this.getSenior();
        // 시설
        this.getFacility();
        // 설비 동력기계
        this.getPowerMachine();
        // 설비 장치기계
        this.getDevice();
      }
    },
    getSenior() {
      this.$http.url = this.managerListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.facilityStatusTable.plantCd,
      }
      this.$http.request((_result) => {
        this.$set(this.facilityStatusTable, 'managers', this.$_.filter(_result.data, { seniorId: "MSI0000007" }));
      },);
    },
    getFacility() {
      this.$http.url = this.facilityListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.facilityStatusTable.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.$set(this.facilityStatusTable, 'facilities', _result.data);
      },);
    },
    getPowerMachine() {
      this.$http.url = this.powerMachineListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.facilityStatusTable.plantCd,
      }
      this.$http.request((_result) => {
        this.$set(this.facilityStatusTable, 'equipPowers', _result.data);
      },);
    },
    getDevice() {
      this.$http.url = this.deviceListUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.facilityStatusTable.plantCd,
      }
      this.$http.request((_result) => {
        this.$set(this.facilityStatusTable, 'equipDevices', _result.data);
      },);
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>